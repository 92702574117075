import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import LogoImage from "../../images/dlogo.svg";

import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Button from "../ui/Button";
import { useAuth } from "../../context/AuthContext";
import AccountDropdown from "../account/AccountDropdown";

const menuItems = [
	{ label: "Home", link: "/" },
	{ label: "Explore", link: "/explore" },
	{ label: "Blog", link: "/blog" },
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const Header = ({ transparent = true }) => {
	const { authModal, token, userData } = useAuth();

	const handleLogin = (e) => {
		e.preventDefault();
		authModal("login");
	};

	return (
		<Popover
			className={classNames(
				"fixed z-50 left-0 right-0 top-0 header",
				transparent ? "bg-transparent" : "bg-black"
			)}
		>
			<div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
					<div className="flex justify-start lg:w-0 lg:flex-1">
						<a href="/">
							<span className="sr-only">The Director</span>
							<img className="h-8 w-auto sm:h-8" src={LogoImage} alt="" />
						</a>
					</div>
					<div className="-mr-2 -my-2 md:hidden">
						<Popover.Button className="bg-black rounded-xl p-2 inline-flex items-center justify-center text-gray-400 hover:text-white hover:bg-gray-100 focus:outline-none focus:ring-0">
							<span className="sr-only">Open menu</span>
							<MenuIcon className="h-6 w-6" aria-hidden="true" />
						</Popover.Button>
					</div>

					<div className="hidden md:flex items-center">
						{menuItems.map((item) => (
							<a
								href={item.link}
								className="text-sm font-medium text-white hover:text-primary mx-4"
							>
								{item.label}
							</a>
						))}
					</div>

					<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
						<a
							href="/download"
							className="blackspace-nowrap text-sm font-medium text-white hover:text-primary mr-8"
						>
							Download
						</a>
						{token ? (
							<AccountDropdown />
						) : (
							<>
								<a
									href="#"
									onClick={handleLogin}
									className="blackspace-nowrap text-sm font-medium text-white hover:text-primary"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-4 w-4 mr-2 mt-[-3px] inline-block"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										strokeWidth={2}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
										/>
									</svg>
									Log In
								</a>
							</>
						)}
					</div>
				</div>

				<Transition
					as={Fragment}
					enter="duration-200 ease-out"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="duration-100 ease-in"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<Popover.Panel
						focus
						className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
					>
						<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-gray-800 divide-y-2 divide-gray-700">
							<div className="pt-5 pb-6 px-5">
								<div className="flex items-center justify-between">
									<div>
										<img
											className="h-8 w-auto"
											src={LogoImage}
											alt="Workflow"
										/>
									</div>
									<div className="-mr-2">
										<Popover.Button className="bg-gray-800 rounded-xl p-2 inline-flex items-center justify-center text-gray-400 hover:text-white hover:bg-gray-100 focus:outline-none focus:ring-0">
											<span className="sr-only">Close menu</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
									</div>
								</div>
							</div>
							<div className="py-6 px-5">
								<div className="grid grid-cols-1 gap-4">
									{menuItems.map((item) => (
										<a
											key={item.label}
											href={item.link}
											className="text-sm font-medium text-white hover:text-primary"
										>
											{item.label}
										</a>
									))}
								</div>
								<div className="mt-6">
									<Button
										label="Get Started"
										link="#"
										className="w-full text-center content-center justify-center "
									/>
									<p className="mt-6 text-center text-sm font-medium text-white">
										Already have an account?{" "}
										<a href="#" className="text-primary hover:text-primary">
											Log In
										</a>
									</p>
								</div>
							</div>
						</div>
					</Popover.Panel>
				</Transition>
			</div>
		</Popover>
	);
};

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
