/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header";
import Footer from "./footer";
import { useAuth } from "../../context/AuthContext";
import Authentication from "../Authentication";
import Seo from "./seo";
import { isMobile } from "../../helpers/common";
import { navigate } from "gatsby";

const Layout = ({
	children,
	loading,
	loginRequired,
	subscriptionRequired,
	seoDescription,
	seoLang,
	seoMeta,
	seoTitle,
	transparentNavbar,
	allowMobile = true,
}) => {
	// const data = useStaticQuery(graphql`
	//   query SiteTitleQuery {
	//     site {
	//       siteMetadata {
	//         title
	//       }
	//     }
	//   }
	// `)
	const { userDara, token } = useAuth();

	const renderContent = () => {
		if (!allowMobile && isMobile()) navigate('/download');
		if (loginRequired) {
			if (!token) {
				return <Authentication />;
			}
		}
		return children;
	};

	return (
		<>
			<Header transparent={transparentNavbar} />
			<Seo
				title={seoTitle}
				description={seoDescription}
				meta={seoMeta}
				lang={seoLang}
			/>
			<main className={transparentNavbar === false ? "mt-[5rem]" : ""}>
				{loading ? "" : renderContent()}
			</main>
			<Footer />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;

//TODO: improve loading fragment
