import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useAuth } from "../../context/AuthContext";
import { classNames } from "../../helpers/common";
import defaultAvatar from "../../images/default-avatar.png";

export default function AccountDropdown() {
	const { userData: user, signOut } = useAuth();
	const userNavigation = [
		{ name: "My Account", href: "/settings" },
		//{ name: "Settings", href: "#" },
		{ name: "Sign out", href: "#", onClick: signOut },
	];
	return (
		<Menu as="div" className="flex-shrink-0 relative">
			<div>
				<Menu.Button className="bg-gray-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
					<span className="sr-only">Open user menu</span>
					<img
						className="h-8 w-8 rounded-full"
						src={user?.avatar?.url || defaultAvatar}
						alt=""
					/>
				</Menu.Button>
			</div>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-gray-800 bg-opacity-80 backdrop-blur-md  ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
					{userNavigation.map((item) => (
						<Menu.Item key={item.name}>
							{({ active }) => (
								<a
									href={item.href}
									className={classNames(
										active ? "bg-gray-700 bg-opacity-50" : "",
										"block py-2 px-4 text-sm text-white"
									)}
									onClick={item.onClick}
								>
									{item.name}
								</a>
							)}
						</Menu.Item>
					))}
				</Menu.Items>
			</Transition>
		</Menu>
	);
}
